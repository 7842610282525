import { useTranslations } from "next-intl";
import Button from "../common/Buttons/Button";
import { logShareToGA } from "../TrackingScripts/LogGA";

const EmailShareButton = ({ slug, pageType, setShareConsented }) => {
  const t = useTranslations("default");

  function handleClick(event) {
    logShareToGA({ type: "e", slug, pageType });
    setShareConsented(true);

    event.preventDefault();

    const shareUrl = `${process.env.NEXT_PUBLIC_SITE_DOMAIN}/a/${slug}?source=em`;
    const popupUrl = `mailto:?subject=${t("title")}&body=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(popupUrl, "_blank");
  }

  return (
    <Button
      id="email-share"
      dataTestId="share-button-email"
      type="button"
      ariaLabel={t("share_on_email")}
      onClick={handleClick}
      color="bg-white"
      hoverColor="hover:bg-gray-100"
      extraClasses="rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input border-gray-400 hover:font-bold h-12 px-6 py-2 flex items-center justify-evenly"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="w-6 h-6 me-2"
        role="img"
      >
        <title>{t("share_on_email")}</title>
        <rect width="20" height="16" x="2" y="4" rx="2"></rect>
        <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
      </svg>
      {t("share_on_email")}
    </Button>
  );
};

export default EmailShareButton;
