import { useTranslations } from "next-intl";
import Button from "../common/Buttons/Button";
import { logShareToGA } from "../TrackingScripts/LogGA";

const TwitterShareButton = ({ slug, pageType, setShareConsented }) => {
  const t = useTranslations("default");

  function handleClick(event) {
    logShareToGA({ type: "t", slug, pageType });
    setShareConsented(true);

    event.preventDefault();

    const shareUrl = `${process.env.NEXT_PUBLIC_SITE_DOMAIN}/a/${slug}?source=tw`;
    const popupUrl = `https://twitter.com/share?url=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(
      popupUrl,
      "_blank",
      "height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
    );
  }

  return (
    <Button
      id="Twitter-share"
      dataTestId="share-button-twitter"
      type="button"
      ariaLabel={t("share_on_twitter")}
      onClick={handleClick}
      color="bg-blue-400"
      hoverColor="hover:bg-blue-500"
      extraClasses="rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input border-blue-400 hover:border-blue-500 hover:font-bold h-12 px-6 py-2 flex items-center justify-evenly text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="w-6 h-6 me-2"
        role="img"
      >
        <title>{t("share_on_twitter")}</title>
        <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
      </svg>
      {t("share_on_twitter")}
    </Button>
  );
};

export default TwitterShareButton;
