import { useTranslations } from "next-intl";
import Button from "../common/Buttons/Button";
import { logShareToGA } from "../TrackingScripts/LogGA";
import { useState } from "react";

const CopyToClipBoardButton = ({ slug, pageType, setShareConsented }) => {
  const t = useTranslations("default");
  const [isCopied, setIsCopied] = useState(false);

  async function handleClick(event) {
    logShareToGA({ type: "c", slug, pageType });
    setShareConsented(true);

    event.preventDefault();
    const shareUrl = `${process.env.NEXT_PUBLIC_SITE_DOMAIN}/a/${slug}?source=clipboard`;
    try {
      await navigator.clipboard?.writeText(shareUrl);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Revert back after 2 seconds
    } catch (err) {
      console.log("Failed to copy URL: ", err);
    }
  }

  return (
    <Button
      id="clipboard-share"
      dataTestId="share-button-clipboard"
      type="button"
      ariaLabel={t("clipboard_aria_label")}
      onClick={handleClick}
      color="bg-gray-600"
      hoverColor="hover:bg-gray-500"
      extraClasses="rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 border-gray-600 hover:border-gray-500 disabled:pointer-events-none disabled:opacity-50 border border-input hover:font-bold h-12 px-6 py-2 flex items-center justify-evenly text-white"
    >
      {isCopied ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="w-6 h-6 me-2"
          role="img"
        >
          <title>{t("clipboard_copied")}</title>
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="w-6 h-6 me-2"
          role="img"
        >
          <title>{t("clipboard_aria_label")}</title>
          <rect width="8" height="4" x="8" y="2" rx="1" ry="1"></rect>
          <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
        </svg>
      )}
      {isCopied ? t("clipboard_copied") : t("clipboard_aria_label")}
    </Button>
  );
};

export default CopyToClipBoardButton;
