import { useTranslations } from "next-intl";
import Button from "../common/Buttons/Button";
import { logShareToGA } from "../TrackingScripts/LogGA";

const FacebookShareButton = ({ slug, pageType, setShareConsented }) => {
  const t = useTranslations("default");

  function handleClick(event) {
    logShareToGA({ type: "f", slug, pageType });
    setShareConsented(true);

    event.preventDefault();

    const shareUrl = `${process.env.NEXT_PUBLIC_SITE_DOMAIN}/a/${slug}?source=fb`;
    const popupUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(
      popupUrl,
      "_blank",
      "height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
    );
  }

  return (
    <Button
      id="facebook-share"
      dataTestId="share-button-facebook"
      type="button"
      ariaLabel={t("share_on_facebook")}
      onClick={handleClick}
      color="bg-blue-600"
      hoverColor="hover:bg-blue-500"
      extraClasses="rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input border-blue-600 hover:border-blue-500 hover:font-bold h-12 px-6 py-2 flex items-center justify-evenly text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="w-6 h-6 me-2"
        role="img"
      >
        <title>{t("share_on_facebook")}</title>
        <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
      </svg>
      {t("share_on_facebook")}
    </Button>
  );
};

export default FacebookShareButton;
