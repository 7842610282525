const CurrencyAmount = (props) => {
  const { language = "en", country = "US", amount, currency } = props;

  const supportedCountryLanguageList = [
    "en-US",
    "en-GB",
    "en-CA",
    "en-NZ",
    "en-AU",
    "en-IE",
    "de-DE",
    "de-AT",
    "de-CH",
    "fr-FR",
    "fr-CA",
    "es-ES",
    "es-AR",
    "es-MX",
  ];

  const countryLanguage = supportedCountryLanguageList.includes(
    `${language}-${country}`
  )
    ? `${language}-${country}`
    : language;

  const currencyOptions = new Intl.NumberFormat(countryLanguage, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  }).resolvedOptions();

  const value = amount.toLocaleString(countryLanguage, {
    ...currencyOptions,
    style: "decimal",
  });

  function formattedNumber(value) {
    switch (currency) {
      case "USD":
      case "NZD":
      case "CAD":
      case "MXN":
      case "BRL":
      case "ARS":
      case "AUD":
        return `$${value}`;
      case "GBP":
        return `£${value}`;
      case "CHF":
        return `CHF ${value}`;
      case "EUR":
        return `${value}€`;
      default:
        return value;
    }
  }

  return formattedNumber(value);
};

export default CurrencyAmount;
